@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');


h1 {
	font-family: 'Caveat', sans-serif;
	font-size: 40px;
}
h1, h2, h3 {
	line-height: 1.4;

}
span.monospace {
	font-family: 'Inconsolata', monospace;
	color: #005f5f;
}

.realcode {
	font-family: 'Inconsolata', monospace;
	color: #005f5f;
	margin: 20px 0px ;
}

body {
	font-family: 'Ubuntu', sans-serif;
	line-height: 1.6;
	color: #094703;
	overflow-x: hidden;
}

a {
	color: #008755;
}

p, h1, h2, h3, h4, .realcode, hr, table, li, th, td, tr, .wstyle{
	max-width: 700px;
	margin: 20px auto;
	padding: 0px 15px;
}

li {
	margin: auto;
	padding: 0px 5px;
}

.navlist {
	display: inline;
	font-size: 28px;
	padding: 13px;
}

.navlist a {
	text-decoration:none;
}

a:hover {
	color: #19493d;
}

.navbar {
	list-style-type: none;
	margin: 0;
	margin-bottom: 20px;
	padding: 5px 5px;
	overflow: hidden;
	background-color: #c5e5cd;
	top: 0;
	width: 100%;
}

.center {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.funBullet {
  /* list-style-type: "— "; */
  /* list-style-type: "♡ "; */
  /*list-style-type: "🌱 "; */
  list-style-type: "🥯 ";
}

.space {
	padding: 10px;
}

.logo {
	border-radius: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
	border-bottom: 1px solid #eee;
}

th, td {
	padding: 5px 15px;
}

tr:hover {
	background-color: #e1f7e6;
}



footer {
	padding: 15px 5px;
	margin-top: 20px;
	background-color: #c5e5cd;
    width: 100%;
	display: block;

}

.flexLayout {
	margin: 0;
    display: flex;
	min-height: calc(100vh - 175px);
    flex-direction: column;
}

.spacer {
	flex: 1 0 auto;
}

.flex-container {
  display: flex;
  justify-content: center;
}

.flex-container > div {
  margin: 10px;
  text-align: left;
  flex: 50%;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 700px) {
  .flex-container {
    flex-direction: column;
	flex: 100%;
  }
}

/* Add this to your CSS file or inside a <style> tag */
p ul, p div, p ol {
  outline: 2px solid pink; /* Highlights the nested ul inside p */
  background-color: rgba(255, 20, 147, 0.2); /* Light pink background for visibility */
}
