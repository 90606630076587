
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');

.books {
	background-color: #eed68b;
  	/* background-image: linear-gradient(to right, #eed68b, #ede7ca, #eed68b); */
  	background-image: linear-gradient(to right, #ede7ca, #f9f5e0, #ede7ca);
	border-radius: 8px;
	color: #282512;
}

.cursive {
	font-family: "Homemade Apple", cursive;
}

button {
	background-color: #e6ccb2; /* Light warm color */
	border: 0px solid #d4a373; /* Slightly darker border */
	border-radius: 5px;
	color: #4b3f31;
	padding: 5px 15px;
	margin: 3px;
	cursor: pointer;
	width:47%;

	/* font-family: 'Caveat', sans-serif; */
	/* font-size: 14px; */

	font-family: 'Ubuntu', sans-serif;
	font-size: 16px;

  	position: relative;
}

button:hover {
	text-decoration: underline;
}

button.selected {
    background-color: #c1a274; /* Even darker shade when active */
	background-color: #d4a373;
}

input[type="text"] {
	border: 1px solid #d4a373;
	border-radius: 8px;
	font-size: 24px;
	padding: 10px;
	margin: 3px 10px;
	width: 90%;
	background-color: #fcfaef;

	font-family: 'Caveat', sans-serif;
}

button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 0px 10px 10px; /* Adjust triangle size */
  border-color: transparent transparent transparent  #4b3f31 ; /* Match the button background */
}

button.selected::before {

  border-width: 10px 10px 0px 10px; /* Adjust triangle size */
  border-color: #4b3f31 transparent transparent transparent ; /* Match the button background */

}
